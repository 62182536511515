import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  classNames,
  generateVideoThumbnail,
  formatBytes,
} from "../../../utils/Utils";

import {
  addNewVideoClue,
  uploadVideoClue,
  uploadVideoClueThumbnail,
} from "../../../redux/actions/gmActions";
import { Tooltip } from "@mui/material";

type Props = {
  id: string;
  puzzles: any;
  handleAddNewClue: (e: any) => void;
  selectedPuzzleObj: any;
  game_id: string;
  addNewVideoClue: (data: any, id: string, puzzle_id: string) => void;
  uploadVideoClue: (file: any, game_doc: any) => void;
  uploadVideoClueThumbnail: (file: any, game_doc: any) => Promise<void>;
  upload_video_clue_progress: number;
  uploaded_video_clue_data: any;
};

const AddVideoClueModal = (props: Props) => {
  const {
    id,
    puzzles,
    handleAddNewClue,
    selectedPuzzleObj,
    game_id,
    upload_video_clue_progress,
    uploaded_video_clue_data,
    uploadVideoClue,
  } = props;

  const [error, setError] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState<RequestInfo | URL>("");

  const video_ref = useRef<HTMLVideoElement>(null);

  const [data, setData] = useState({
    name: "",
    file: {
      name: "",
      url: "",
      duration: 0,
      size: 0,
    },
    thumbnail: "",
    stop_timer: false,
    trigger_alert : false
  });

  const updateData = (name: string, value: any) => {
    const newObj = {
      ...data,
      [name]: value,
    };
    setData(newObj);
  };

  async function getThumbnailFile(clue_data: any) {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(videoThumbnail);
      const blob = await response.blob();
      const file = new File([blob], "thumb_" + clue_data.file_name, {
        type: "image/jpeg",
        lastModified: Number(new Date()),
      });

      props.uploadVideoClueThumbnail(file, game_id).then((url) => resolve(url));
    });
  }

  useEffect(() => {
    if (uploaded_video_clue_data) {
      if (videoThumbnail) {
        getThumbnailFile(uploaded_video_clue_data).then((url) => {
          setData({
            ...data,
            name: !data.name ? uploaded_video_clue_data.name : data.name,
            file: uploaded_video_clue_data,
            thumbnail: String(url),
          });
        });
      }
    }
  }, [uploaded_video_clue_data]);

  const title = selectedPuzzleObj ? selectedPuzzleObj.name : "";

  const uploadFile = async (file: any) => {
    console.log("file", file);
    setError("");
    // Check if size is less than 100MB
    if (file.size <= 100000000  ) {
      const thumbnail: any = await generateVideoThumbnail(file);
      setVideoThumbnail(thumbnail);
      uploadVideoClue(file, game_id);
    } else {
      setError("File is too big. Max size is 100MB.");
    }
  };


  console.log("DATA", data);

  return (
    <div
      className="modal effect-scale"
      id="addVideoClueModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered max-w-[355px]"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-4">
              <div className="flex items-center mb-1 space-x-3">
              <p className="text-lg text-white font-medium">
                Upload Video Clue
              </p>
              <Tooltip title="Video clues are in beta. They have no guarantees, may have issues or may change or end abruptly." placement="top" arrow>
                <p className="bg-purple-600 px-1 rounded text-white">BETA</p>
              </Tooltip>
              </div>
              {/* <p>Upload a custom image clue</p> */}
            </div>

            <div className="flex flex-col">
              {/* <label className="label-dark">File</label> */}
              <div
                className={classNames(
                  "flex items-center justify-center w-full",
                  uploaded_video_clue_data ? "hidden" : null
                )}
              >
                <label
                  //for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 bg-v1_dark-hover hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center text-center justify-center">
                    {upload_video_clue_progress > 0 ? (
                      <div>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Uploading: {upload_video_clue_progress.toFixed(0)}%
                          </span>
                        </p>
                        <div className="h-1 bg-gray-600">
                          <div
                            className="h-full bg-blue-500 transition-width"
                            style={{
                              width: `${upload_video_clue_progress.toFixed(
                                0
                              )}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <i className="fa-regular fa-upload text-2xl mb-2"></i>
                        <p className="mb-2 text-sm text-white dark:text-gray-500 font-medium">
                          Drag and drop a video file to upload
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-500">
                          .mp4, .mov (100MB max)
                        </p>
                      </div>
                    )}

                    {error && (
                      <p className="text-red-500 font-medium mt-3">
                        {error && error}
                      </p>
                    )}
                  </div>
                  <input
                    onChange={(e: any) => uploadFile(e.target.files[0])}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".mp4, .mov"
                  />
                </label>
              </div>

              {uploaded_video_clue_data && (
                <div className={classNames("w-full flex flex-col space-y-2")}>
                  {/* <img
                    src={videoThumbnail && String(videoThumbnail)}
                    className="w-full h-auto"
                  ></img> */}
                  {data && data.file && (
                    <video
                      className="mx-0 mb-2"
                      style={{
                        borderRadius: "8px",
                        margin: "0 auto",
                      }}
                      controls
                      preload="auto"
                      autoPlay={false}
                      loop={false}
                      src={data.file ? data.file.url : ""}
                    ></video>
                  )}
                  <div className="w-[83%]">
                    {/* <p className="text-white whitespace-nowrap overflow-hidden text-ellipsis w-full">{uploaded_video_clue_data.name}</p> */}
                    <div className={classNames("flex flex-col mb-3")}>
                      <label className="label-dark">Clue Name</label>
                      <input
                        disabled={!uploaded_video_clue_data ? true : false}
                        value={data.name}
                        onChange={(e) => updateData("name", e.target.value)}
                        type="text"
                        className="input-dark disabled:animate-pulse"
                        placeholder="Name"
                      ></input>
                    </div>

                    <div className="flex items-center space-x-4 mb-3">

                    <div className="flex items-center">
                      <input
                        id="trigger_alert"
                        type="checkbox"
                        onChange={(e) => setData({
                          ...data, trigger_alert: e.target.checked
                        })}
                        checked={data.trigger_alert}
                        className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                      />
                      <label className="label-dark mb-0 pb-0">
                        Trigger alert tone
                      </label>
                    </div>

                    {/* <div className="flex items-center">
                      <input
                        id="stop_timer"
                        type="checkbox"
                        onChange={(e) => setData({
                          ...data, stop_timer: e.target.checked
                        })}
                        checked={data.stop_timer}
                        className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                      />
                      <label className="label-dark mb-0 pb-0">
                        Stop timer on play
                      </label>
                    </div> */}

                      </div>

                    <div className="flex items-center space-x-4">
                      <p className="text-sm text-gray-500">
                        {uploaded_video_clue_data
                          ? formatBytes(uploaded_video_clue_data.size)
                          : "18MB"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-4">
              <p className="text-gray-500 text-xs">
                By submitting your videos to Bolt, you acknowledge that you
                agree to Bolt's{" "}
                <a
                  className="hover:text-white text-blue-500 duration-100"
                  target="_blank"
                  href="https://www.boltgamemaster.com/terms-of-service"
                >
                  Terms of Service.
                </a>
              </p>
            </div>
          </div>

          <div className="modal-footer-dark">
            {data.file.url ? (
              <button
                onClick={() => {
                  props.addNewVideoClue(data, game_id, selectedPuzzleObj.id);
                  setTimeout(() => {
                    setData({
                      name: "",
                      file: {
                        name: "",
                        url: "",
                        duration: 0,
                        size: 0,
                      },
                      thumbnail: "",
                      trigger_alert: false,
                      stop_timer: false
                    });
                  }, 1000);
                }}
                type="button"
                className="button-blue-dark w-full"
                data-dismiss="modal"
              >
                Add video clue
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="button-blue-dark opacity-50 w-full"
                data-dismiss="modal"
              >
                Add video clue
              </button>
            )}
            {/* <button
              type="button"
              className="button-outline-dark ml-2"
              data-dismiss="modal"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    upload_video_clue_progress: state.settings.upload_video_clue_progress,
    uploaded_video_clue_data: state.settings.uploaded_video_clue_data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewVideoClue: (data: any, id: string, puzzle_id: string) =>
      dispatch(addNewVideoClue(data, id, puzzle_id)),
    uploadVideoClue: (file: any, game_doc: string) =>
      dispatch(uploadVideoClue(file, game_doc)),
    uploadVideoClueThumbnail: (file: any, game_doc: string) =>
      dispatch(uploadVideoClueThumbnail(file, game_doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoClueModal);
