import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import FadeIn from "react-fade-in";
import Tooltip from "@mui/material/Tooltip";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { addNewRoom, deleteRoom } from "../../redux/actions/gmActions";
import NewRoomModal from "./modals/NewRoomModal";
import SubsModal from "../account/modals/SubsModal";
import { handleCheckoutClick, classNames } from "../../utils/Utils";
import Upgrade from "./components/Upgrade2";

function Dashboard2(props) {
  const { account, rooms } = props;

  const [permissions, setPermissions] = useState({
    isAdmin: false,
    role: null,
    canEditLiveWindow: false,
  });
  const [roomLimit, setRoomLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [charge_failed, setChargeFailed] = useState(false);
  const [stripe_id, setStripeId] = useState(null);
  const [subscription_status, setSubscriptionStatus] = useState(null);
  const [subscription_active, setSubscriptionActive] = useState(false);
  const [searchWords, setSearchWords] = useState("");

  useEffect(() => {
    if (account) document.title = "Dashboard - Escape Room Studio";
  }, []);

  useEffect(() => {
    if (account) {
      const perms = account && account.permissions ? account.permissions : null;

      function search(id, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].user_id === id) {
            return myArray[i];
          }
        }
      }

      if (perms) {
        const gm_permissions = search(props.uid, perms);
        if (gm_permissions) {
          setPermissions(gm_permissions);
          setLoading(false);
        }
      } else {
        setPermissions({ isAdmin: true });
        setLoading(false);
      }
    }

    if (account) {
      const plan_type = account ? account.subscription.product_id : null;
      const subscription_status_type =
        account && account.subscription_status
          ? account.subscription_status.type
          : null;

      // Plan Types
      if (plan_type === "prod_JDMsnc2gCLWWkA" || plan_type === undefined)
        setRoomLimit(1);
      if (plan_type === "prod_JDMvOig4rtxg0U") setRoomLimit(100);
      if (plan_type === "prod_JDMw9iECHFJwCs") setRoomLimit(100);

      // Set Charge Failed
      if (subscription_status_type === "charge.failed") setChargeFailed(true);

      // Set Stripe Customer ID
      const stripe_customer_id = account.subscription.customer_id
        ? account.subscription.customer_id
        : null;

      if (stripe_customer_id) setStripeId(stripe_customer_id);

      // Set Subscription Status
      if (subscription_status_type)
        setSubscriptionStatus(subscription_status_type);

      // Set Subscription Active
      if (plan_type) setSubscriptionActive(true);
    }
  }, [account]);

  if (loading) {
    return (
      <div className="content ht-100v pd-0">
        <div
          className="text-center"
          style={{
            left: "50%",
            marginLeft: "-2em",
            position: "absolute",
            top: "40%",
          }}
        >
          <div
            className="spinner-border"
            style={{ width: "5rem", height: "5rem" }}
          ></div>
        </div>
      </div>
    );
  }

  const search = (search) => {
    console.log(search);
    setSearchWords(search);
  };

  return (
    <div>
      {charge_failed && (
        <div style={{ marginTop: "20px" }}>
          <div
            className="alert alert-danger d-flex align-items-center"
            role="alert"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <svg
                width="24px"
                fill="currentColor"
                style={{ marginRight: "10px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M96 360C96 346.7 106.7 336 120 336H168C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360zM224 360C224 346.7 234.7 336 248 336H360C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360zM96 280C96 266.7 106.7 256 120 256H456C469.3 256 480 266.7 480 280C480 293.3 469.3 304 456 304H120C106.7 304 96 293.3 96 280zM456 128C469.3 128 480 138.7 480 152V200C480 213.3 469.3 224 456 224H376C362.7 224 352 213.3 352 200V152C352 138.7 362.7 128 376 128H456zM0 96C0 60.65 28.65 32 64 32H512C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V96C528 87.16 520.8 80 512 80H64C55.16 80 48 87.16 48 96z" />
              </svg>{" "}
              <b>Payment Failed</b>
              <span style={{ marginLeft: "5px" }}>
                {" "}
                Please update your payment method.
              </span>
            </div>
            <a
              className="btn btn-xs btn-primary"
              style={{ marginLeft: "5px" }}
              href="/account/billing"
            >
              {" "}
              Go to billing
              <i
                className="fa-solid fa-arrow-right"
                style={{ marginLeft: "5px" }}
              ></i>
            </a>
          </div>
        </div>
      )}

      <div className="py-8 px-4 sm:px-0" style={{ margin: "0 auto" }}>
        <Upgrade
          rooms={rooms}
          subscription_status={subscription_status}
          subscription_active={subscription_active}
        />

        <div className="flex items-center justify-between">
          <div className="mb-0">
            <p className="text-2xl font-semibold">My Dashboard</p>
          </div>

          <div
            style={
              permissions.isAdmin || permissions.role === "Admin"
                ? null
                : { display: "none" }
            }
          >
            {subscription_active && (
              <a
                href="#NewRoomModal"
                data-toggle="modal"
                className="bg-black py-2.5 px-3 text-white hover:bg-gray-800 duration-100 font-medium uppercase"
              >
                Create Room
              </a>
            )}

            {rooms && rooms.length === 0 && (
              <a
                href="#NewRoomModal"
                data-toggle="modal"
                className="bg-black py-2.5 px-3 text-white hover:bg-gray-800 duration-100 font-medium uppercase"
              >
                Create Room
              </a>
            )}
          </div>
        </div>

        <div className="pt-6 pb-3 flex justify-end">
          <input
            className="bg-gray-100 py-2.5 px-4 text-black"
            placeholder="Search"
            onChange={(e) => search(e.target.value)}
          ></input>
        </div>

        <div>
          {rooms && rooms.length === 0 ? (
            <div className="py-12">
              <a
                href="#NewRoomModal"
                data-toggle="modal"
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2"
              >
                <div className="flex items-center justify-center space-x-1 mb-4">
                  <i class="fa-kit fa-solid-door-open-circle-plus text-3xl"></i>
                  
                </div>
                <span className="button-black-light">Create a new room</span>
              </a>
            </div>
          ) : (
            <div className="pb-12 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
              {rooms
                ? rooms.map((item, index) => {
                    const stats = item.stats ? item.stats : null;

                    if (
                      !item.name
                        .toLowerCase()
                        .includes(searchWords.toLowerCase())
                    ) {
                      console.log("NO", item.name);
                      return null;
                    } else {
                      return (
                        <div
                          key={index}
                          className={classNames(
                            "w-full h-full border border-gray-300 flex",
                            !subscription_active && index > 0
                              ? "opacity-50"
                              : "opacity-100"
                          )}
                        >
                          {/* <div
                            style={{
                              background: item.thumbnail
                                ? "url(" + item.thumbnail.url + ")"
                                : "#eee",
                              backgroundSize: "cover",
                            }}
                            className="md:w-[355px] md:h-[200px] bg-gray-100 flex justify-center items-center group transition duration-150"
                          >
                            <div
                              className={classNames(
                                "w-full h-full bg-white transition duration-150",
                                !subscription_active && index > 0
                                  ? "opacity-0"
                                  : "group-hover:opacity-80 opacity-0"
                              )}
                            ></div>

                            <a
                              href={
                                !subscription_active && index > 0
                                  ? null
                                  : "/r/" + item.id + "?view=puzzles"
                              }
                              className={classNames(
                                "bg-black text-white py-2.5 px-3 transition duration-100 font-medium hover:bg-gray-800 uppercase absolute opacity-0 group-hover:opacity-100",
                                !subscription_active && index > 0
                                  ? "hidden"
                                  : null
                              )}
                            >
                              Go to Game
                            </a>
                          </div> */}

                          <div className="py-6 px-8 flex-grow-1">
                            <div className="mb-4 flex justify-between">
                              <div>
                                <a
                                  href={
                                    !subscription_active && index > 0
                                      ? null
                                      : "/r/" + item.id + "?view=puzzles"
                                  }
                                  target="_blank"
                                  className="font-semibold text-lg hover:text-black"
                                >
                                  {item.name}
                                </a>
                                {/* <p className="text-gray-500 mb-1">{item.id}</p> */}
                                <div className="flex space-x-4 mt-2">
                                  <p>{item.stats.escape_rate.toFixed(2)}%</p>
                                  <p>{item.stats.wins} wins</p>
                                  <p>{item.stats.losses} losses</p>
                                </div>

                                {!subscription_active && index !== 0 ? (
                                  <p>Please subscribe to reactivate.</p>
                                ) : (
                                  <p></p>
                                )}
                              </div>

                              <div>
                                <Menu
                                  as="div"
                                  className="relative inline-block text-left"
                                >
                                  <div>
                                    <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-offset-gray-100">
                                      <span className="sr-only">
                                        Open options
                                      </span>
                                      <EllipsisHorizontalIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href={"/settings/game/" + item.id}
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900 hover:text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              Settings
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>

                            <div className="flex space-x-2">
                              {/* <Tooltip title={
                                    <React.Fragment>
                                      <div className="text-sm text-center">
                                        <p>This room has expired.</p>
                                        <p>Please <a href="#">subscribe</a> to reactivate.</p>
                                      </div>
                                    </React.Fragment>
                                  } placement="bottom" arrow>
                                <a href={!subscription_active && index > 0 ? null : "/r/" + item.id + "?view=puzzles"} target="_blank" className="border border-gray-300 py-2 px-3 hover:bg-gray-200 transition duration-100 font-medium hover:text-black">
                                  Control Panel
                                  {!subscription_active && index !== 0 ? <i class="fa-solid fa-circle w-2 ml-2 text-red-500"></i> : null }
                                  </a>
                                  </Tooltip> */}

                              <a
                                href={
                                  !subscription_active && index > 0
                                    ? null
                                    : "/r/" + item.id + "?view=puzzles"
                                }
                                className="border  border-gray-300 py-2 px-3 hover:bg-gray-200 transition duration-100 font-medium hover:text-black"
                              >
                                <i class="fa-light fa-display mr-2"></i>
                                Console
                                {!subscription_active && index !== 0 ? (
                                  <i class="fa-solid fa-circle w-2 ml-2 text-red-500"></i>
                                ) : null}
                              </a>

                              {/* {!subscription_active && index !== 0 ? (
                                      <p></p>
                                    ) : (
                                      <a href={!subscription_active && index > 0 ? null : "/r/" + item.id + "/live"} target="_blank" className="border border-gray-300 py-2 px-3 hover:bg-gray-200 transition duration-100 font-medium hover:text-black"><i className="fa-regular fa-window mr-2"></i>Player Window</a>
                                    )} */}

                              <a
                                href={
                                  !subscription_active && index > 0
                                    ? null
                                    : "/settings/game/" + item.id
                                }
                                className="border  border-gray-300 py-2 px-3 hover:bg-gray-200 transition duration-100 font-medium hover:text-black"
                              >
                                <i class="fa-light fa-palette mr-2"></i>
                                Customize
                                {!subscription_active && index !== 0 ? (
                                  <i class="fa-solid fa-circle w-2 ml-2 text-red-500"></i>
                                ) : null}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          )}
        </div>
      </div>

      <NewRoomModal />
      <SubsModal
        handleCheckoutClick={handleCheckoutClick}
        stripe_id={stripe_id}
        subscription_status={subscription_status}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log("state2", state);

  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;

  return {
    rooms: state.firestore.ordered.gamemaster,
    auth: state.firebase.auth,
    account_id: account_id,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    profile: state.firebase.profile,
    app: state.app,
    account: state.firestore.ordered.user_accounts[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewRoom: (name) => dispatch(addNewRoom(name)),
    deleteRoom: (room_id) => dispatch(deleteRoom(room_id)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "gamemaster",
      where: [["account_id", "==", props.account_id]],
      orderBy: ["created_at", "desc"],
    },
  ])
)(Dashboard2);
